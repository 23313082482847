<template>
  <div>
    <title>SIM INVENTORY ~ ADD BUYER PRODUCTION PROCESS DATA</title>
    <section class="content-header">
      <h1>
        Add Buyer Production Process Data
        <br />
        <h4>Please enter transaction data for the buyer's production process</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Buyer Production Process Transactions</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">Buyer's Production Process Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Production Number</label>
                  <input
                    type="text"
                    v-model="isidata.prodnumb"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Production Number"
                  />
                  <input
                    type="hidden"
                    v-model="isidata.idordbuyersch"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="isidata.notesprosesprod"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Notes"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Proses Date</label>
                  <input
                    type="date"
                    v-model="isidata.prosesdate"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Process Results</label>
                  <input
                    type="number"
                    v-model="isidata.prosesresult"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Process Results"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                > <i class="fa fa-save"></i>
                  Save
                </button>
                &nbsp;
                <router-link to="/proses-produksi">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
        
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addmasterdata",
  data() {
    return {
      loading: false,
      isidata: {
        prodnumb: "",
        idordbuyersch: "",
        notesprosesprod: "",
        prosesdate: "",
        prosesresult: "",
      }
    };
  },
  created(){
      this.isidata.idordbuyersch = this.$route.params.id
  },
  methods: {
    async saveData() {
      this.loading = true;
      var prodnumber = this.isidata.prodnumb;
      var catatan = this.isidata.notesprosesprod;
      var tglproses = this.isidata.prosesdate;
      var hasilproses = this.isidata.prosesresult;
      if (prodnumber == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Production Number can't be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (catatan == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes Production Process can't be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (tglproses == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Date Production Process can't be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (hasilproses == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Results Production Process can't be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        var kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          nomor_produksi: this.isidata.prodnumb,
          id_order_buyer_schedule: this.isidata.idordbuyersch,
          notes: this.isidata.notesprosesprod,
          tanggal_proses: this.isidata.prosesdate,
          kode_user: kodeuser,
          hasil_proses: this.isidata.prosesresult,
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        const urlAPISaveData = this.$apiurl + "proses_produksi/saveproses_produksi";
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPISaveData, paramdata, { headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              this.$router.push({ name: "prosesproduksiheader" });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
